import { extendTheme } from '@mui/joy/styles';

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: 'var(--joy-palette-neutral-100)',
        },
        primary: {
          50: '#F2F7FF',
          100: '#DCEBFE',
          200: '#BDDAFE',
          300: '#91C3FC',
          400: '#60A5FA',
          500: '#3479E8',
          600: '#2362EA',
          700: '1D4FD7',
          800: '#1D3FAE',
          900: '#1E3B8A',
          solidBg: 'var(--joy-palette-primary-600)',
          solidHoverBg: 'var(--joy-palette-primary-500)',
          solidActiveBg: 'var(--joy-palette-primary-400)',
        },
      },
    },
    dark: {
      palette: {
        neutral: {
          outlinedBorder: 'var(--joy-palette-neutral-700)',
        },
        primary: {
          50: '#1D223F',
          100: '#0A318C',
          200: '#1347CC',
          300: '#1055EA',
          400: '#357AEA',
          500: '#2E88F6',
          600: '#50A1FF',
          700: '#7AB7FF',
          800: '#DCEBFE',
          900: '#F0F6FF',
          solidBg: 'var(--joy-palette-primary-700)',
          solidColor: 'var(--joy-palette-common-black)',
          solidHoverBg: 'var(--joy-palette-primary-600)',
          solidActiveBg: 'var(--joy-palette-primary-400)',
        },
        background: {
          body: 'var(--joy-palette-common-black)',
          surface: 'var(--joy-palette-neutral-900)',
        },
      },
    },
  },
  fontFamily: {
    display: "'Inter', var(--joy-fontFamily-fallback)",
    body: "'Inter', var(--joy-fontFamily-fallback)",
  },
  components: {
    JoyAutocomplete: {
      styleOverrides: {
        root: {
          boxShadow: 'var(--joy-shadow-xs)',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          boxShadow: 'var(--joy-shadow-xs)',
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          boxShadow: 'var(--joy-shadow-xs)',
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        root: {
          boxShadow: 'var(--joy-shadow-xs)',
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'outlined' &&
            ownerState.color !== 'context' && {
            '&:not(.Joy-focused):hover::before': {
              boxShadow: `inset 0 0 0 2px ${theme.vars.palette?.[ownerState.color]?.outlinedBorder
                }`,
            },
          }),
        }),
        input: {
          caretColor: 'var(--Input-focusedHighlight)',
        },
      },
    },
  },
});