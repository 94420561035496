import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import customTheme from './components/utils/theme';

const Welcome = React.lazy(() => import('./components/welcome/Welcome'))
const Login = React.lazy(() => import('./components/login/Login'))
const Register = React.lazy(() => import('./components/register/Register'))
const Dashboard = React.lazy(() => import('./components/dashboard/Dashboard'))

const App = () =>
  <Suspense fallback={<>
    <Backdrop style={{ zIndex: 10, color: '#fff' }} open={true} >
      <CircularProgress color="inherit" />
    </Backdrop></>}>
    <CssVarsProvider theme={customTheme} defaultMode="dark">
      <CssBaseline />
      <Router>
        <Routes>
          <Route exact path='/' element={<Welcome />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/register/:referralParam?" element={<Register />} />
          <Route exact path="/home" element={<Dashboard />} />
          <Route exact path="*" element={<h2>Page Not Found</h2>} />
        </Routes>
      </Router>
    </CssVarsProvider>
  </Suspense>

export default App;